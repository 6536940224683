import React from "react";
import './banner.css';


function Banner() {
  return (
    <a target="_blank" rel="noreferrer"href="https://wa.me/61999630698?text=Ol%C3%A1!%20Gostaria%20de%20solicitar%20um%20or%C3%A7amento!%0A">
      <div className="container-banner"></div>  
    </a>
    
  );
}

export default Banner;
