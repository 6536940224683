import React from "react";
import Home from "./pages/home/home";
import './App.css';

function App() {
  return (
    <div className="App-container">
      <Home />
    </div>
   
  );
}

export default App;
