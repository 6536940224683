import React from "react";
import './contact.css';

function Contact() {
  return (
  <>
      <h1 style={{fontWeight:'900'}} className="title-contact">
        SOBRE NÓS
      </h1>
      <div className="container-contact" >
      <div className="text-container">
        <p style={{margin: '4% auto 4% auto'}}>
          A Real Locadora atua no mercado do Distrito Federal há 9 anos.
          Oferece soluções eficientes para içamentos, remoções industrias e transportes especiais. Dispõe de equipamentos com tecnologia de ponta em Munks, guindastes, cestos de fibra e outros. Toda a frota é constantemente revisada, o que assegura confiança e segurança em cada serviço prestado.
          <br />
          A equipe da Real é formada por profissionais capacitados, experientes e orientados a oferecer sempre as melhores alternativas para as
          necessidades específicas de cada cliente.
        </p>
      </div>
      <div className="redes">
        <div className="telefone">
          <a target="_blank" rel="noreferrer"href="https://wa.me/61999630698?text=Ol%C3%A1!%20Gostaria%20de%20solicitar%20um%20or%C3%A7amento!%0A">
            <div>
            </div>
          </a>
          <p>(61)99963-0698</p>
        </div>
        <div className="instagram">
          <a target="_blank" rel="noreferrer"href="https://www.instagram.com/realmunck/">
            <div>
            </div>
          </a>
          <p> @realmunck</p>
        </div>
      </div>
      
      <div className="email">
        <a style={{flex: 1}} target="_blank" rel="noreferrer"href="mailto:contato@realguindastes.com.br">
          <div>
          </div>
        </a>
        <p style={{flex: 1}}>contato@realguindastes.com.br</p>
      </div>
      
      <div className="endereco">
        <a target="_blank" rel="noreferrer"href="https://www.google.com/maps/dir/-15.8139869,-47.8937214/real+guindastes/@-15.809902,-47.9641798,13z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x935a3053dcb912bd:0xd465b3354f51b480!2m2!1d-47.9636249!2d-15.7969529">
          <div>
          </div>
        </a>
        <p style={{textAlign: 'center'}}>SIA Trecho 17 rua 14 lotes 70/90 - Brasília/DF </p>
      </div>
    </div>
  </>
    
  );
}

export default Contact;
