import React from "react";
import Banner from "../../components/banner/banner";
import Contact from "../../components/contact/contact";
import Header from "../../components/header/header";
import Services from "../../components/services/services";
import './home.css';
import Client from "../../components/client/client";
import Footer from "../../components/footer/footer";

function Home() {
  return (
    <div >
      <Header />
      <Banner />
      <Services />
      <Contact />
      <Client />
      <Footer />
    </div>
  );
}

export default Home;
