import React from "react";
import './footer.css';


function Footer() {
  return (
  <div className="footer-container">
    <p>© 2000 Real Guindastes - Todos os direitos reservados</p>
    <p>contato@realguindastes.com.br</p>
  </div>
    
  );
}

export default Footer;
