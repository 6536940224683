import React from "react";
import Grid from '@mui/material/Unstable_Grid2';
import './services.css';
import Button from '@mui/material/Button';

function Services() {
  return (
  <>
    <h1 className="title-service" style={{fontWeight:'900', textAlign: 'center', color: 'white'}}> SERVIÇOS </h1>
    <div className="container">
      <Grid style={{ justifyContent: 'center' }}container spacing={3}>
        <Grid className="service" xs={12} md={3}>
          <h1>LOCAÇÃO DE MUNCK & GUINDASTE</h1>
          <p>Nós alugamos munck e guindaste em bom estado para mover cargas pesadas conforme suas necessidades.</p>
          <Button target="_blank" rel="noreferrer"href="https://wa.me/61999630698?text=Ol%C3%A1!%20Gostaria%20de%20solicitar%20um%20or%C3%A7amento!%0A" className="btn" variant="outlined">ORÇAR LOCAÇÃO</Button>
        </Grid>
        <Grid className="service" xs={12} md={3}>
          <h1>PLANO DE<br /> RIGGING</h1>
          <p>Oferecemos plano de rigging especializado para garantir a segurança e eficiência na elevação e movimentação de cargas.</p>
          <Button target="_blank" rel="noreferrer"href="https://wa.me/61999630698?text=Ol%C3%A1!%20Gostaria%20de%20solicitar%20um%20or%C3%A7amento!%0A" className="btn" variant="outlined">ORÇAR PLANO</Button>
        </Grid>
        <Grid className="service" xs={12} md={3}>
          <h1>REMOÇÃO DE CARGA PESADA</h1>
          <p>Nós somos especializados em remover cargas pesadas com segurança e eficiência, incluindo equipamentos, máquinas e estruturas grandes.</p>
          <Button target="_blank" rel="noreferrer"href="https://wa.me/61999630698?text=Ol%C3%A1!%20Gostaria%20de%20solicitar%20um%20or%C3%A7amento!%0A" className="btn" variant="outlined">ORÇAR REMOÇÃO</Button>
        </Grid>
      </Grid>  
    </div>
  </>
    
  );
}

export default Services;