import React from "react";
import './header.css';

function Header() {
  return (
    <div className="header">
      <a href="/">
        <img src="/logo.png" alt="Logo da empresa" />
      </a>
      <div className="title">
        <h1 style={{fontWeight:'900'}}>Real Guindastes</h1>
        <h2 style={{fontWeight:'600'}}>Locadora & Transportadora</h2>
      </div>
      
    </div>
  );
}

export default Header;