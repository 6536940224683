import React from "react";
import './client.css';
import Grid from '@mui/material/Unstable_Grid2';

function Client() {
  return (
  <>
      <h1 style={{fontWeight:'900'}} className="title-client">
        CLIENTES
      </h1>
      <div className="clients-container">
        <Grid style={{width: '98%', margin: "15px auto"}} container spacing={2}>
          <Grid xs={6}>
            <div className="caixa"></div>
          </Grid>
          <Grid xs={6}>
            <div className="parkshop"></div>
          </Grid>
          <Grid xs={6}>
            <div className="mc"></div>
          </Grid>
          <Grid xs={6}>
            <div className="petrobras"></div>
          </Grid>
          <Grid xs={6}>
            <div className="brasal"></div>
          </Grid>
          <Grid xs={6}>
            <div className="brb"></div>
          </Grid>
          <Grid xs={6}>
            <div className="gasbras"></div>
          </Grid>
          <Grid xs={6}>
            <div className="senai"></div>
          </Grid>
        </Grid>
        <p>
          A Real Locadora busca sempre construir um relacionamento de amizade e respeito com seus clientes. Isso eleva a qualidade do serviço prestado e abre as portas para que novas parcerias voltem a acontecer. Conheça alguns clientes já atendidos por nós.
        </p>
      </div>
  </>
    
  );
}

export default Client;
